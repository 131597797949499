// CAROUSEL HOME
.courses-item img {
    width: 124px;
}

.courses-item .caption p {
    font-family: "MyriadPro-Cond", sans-serif !important;
    font-size: 20px;
    color: #004261;
    text-align: left;
    padding: 1rem;
}

.courses-item .course-date {
    text-align: left;
    padding-bottom: .5rem;
    padding-left: 1rem;
    font-family: "MyriadPro-Regular", sans-serif;
    text-decoration: none;
    color: #1c6ba4 !important;
    font-size: 0.688rem;
}

@media only screen and (max-width: 420px) {
    .news-info {
      height: 265px; 
    }
}
.noticia-destaque-carrossel{
  width: 100%;
  height: auto;
}
.news-info {
  height: 295px; 
  display: flex; 
  flex-direction: column
}

.news-arrow {
    color: rgba(255, 255, 255, 0.8);
    font-size: 1.3em;
    font-weight: bold;
    margin: 20px;
  }
.news-arrow:hover{
  color: #fff;
}
  .news-radio-buttons input[type="radio"] {
    display: none;
  }
  
  
  .news-radio-buttons label {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 2px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
  }
  
  .news-radio-buttons input[type="radio"]:checked + label {
    background-color: #fff;
  }

  .news-right {
    cursor:pointer;
  }

  .news-left {
    cursor:pointer;
  }
  
  // @media (max-width: 767px) {
  //   .news-image-carousel {
  //     display: none;
  //   }
  // }